import moment from "moment";
import ApiService from "../services/ApiService";
interface DesignStock {
  id: number;
  qty: string;
  remark: string;
  stock_type: string;
  design_id: string;
  design_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const DesignStocks: Array<DesignStock> = [];

const deleteDesignStock = function (item: DesignStock) {
  return ApiService.delete(`design-stock/${item.id}/`);
};

const addDesignStock = function (item, id) {
  delete item.created_by
  if (id) {
    return ApiService.put(`design-stock/${id}/`, item);
  } else {
    return ApiService.post("design-stock/", item);
  }
};

const exportDesignStockData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/design-stock/?designStock_id=" + ids,
      "designStock-data"
    ).then((data) => res(data));
  });
};
const exportPDFDesignStocks = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/design-stock/?designStock_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const designStockObjClone = function () {
  return {
    id: 0,
    qty: "",
    remark: "",
    stock_type: "",
    design_id: "",
    design_data: {},
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: DesignStock = designStockObjClone();
  newItem.id = item.id;
  newItem.qty = item.qty;
  newItem.remark = item.remark;
  newItem.stock_type = item.stock_type;
  newItem.design_id = item.design_id;
  newItem.design_data = item.design_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getDesignStockById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`design-stock/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};


const getStockCountById = function (id) {
  return new Promise((res, rej) => {
    ApiService.getWithoutSlashNoCancel(`design-stock/${id}/count`).then(function (response) {
      res(response.data);
    });
  });
};
const getDesignStocks = function (
  pageNumber = 1,
  searchData,
  status = "",
  design_id = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      design_id: design_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`design-stock/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceDesignStock = jsonToInterface;
const getEmptyObjOfDesignStock = designStockObjClone;
export {
  DesignStock,
  getDesignStocks,
  deleteDesignStock,
  addDesignStock,
  getDesignStockById,
  jsonToInterfaceDesignStock,
  designStockObjClone,
  exportDesignStockData,
  exportPDFDesignStocks,
  getEmptyObjOfDesignStock,
  getStockCountById
};

export default DesignStocks;
